import React, { useEffect } from 'react'

interface BusinessModalProps {
    onClose?: () => void;
    children: React.ReactNode;
}

const BusinessModal = (props: BusinessModalProps) => {
    const { onClose, children } = props;

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          onClose?.();
        }
      };
  
      document.addEventListener("keydown", handleKeyDown);
  
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [onClose]);

  return (
    <div className="fixed w-[calc(100%-75px)] top-[70px] right-0 h-screen bg-[rgba(0,0,0,0.65)] flex overflow-y-auto justify-end z-[90]">
        {children}
    </div>
  )
}

export default BusinessModal