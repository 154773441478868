import React, { useEffect, useState } from "react";
import Input from "./Input";
import Button from "./Button";
import { getItem } from "../../utils/localStorage";
import { verifyOtp } from "../../service/auth";
import { useLoader } from "../../context/LoderContext";

interface Props {
  otp: string;
  setOtp: (value: string) => void;
  setOTPVerified: (value: boolean) => void;
}

interface User {
  email: string;
  password: string;
}

const VerifyOtp = ({ otp, setOtp, setOTPVerified }: Props) => {
  const [loginMessage, setLoginMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const { hideLoader, showLoader } = useLoader();

  const handleVerifyOtp = async () => {
    showLoader();
    const user = getItem("user") as User | null;
    const email = sessionStorage.getItem("email") || user?.email;
    if (!email) {
      console.log("Email not found");
    }
    const response = await verifyOtp(email, otp);
    hideLoader();
    if (response?.code === 200) {
      setLoginMessage(response.message);
      setShowAlert(true);
      setOTPVerified(true);
    }
  };

  return (
    <>
      {/* <AlertBadge
        bgColor={"bg-green-500"}
        message={loginMessage}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      /> */}
      <div>
        <Input
          value={otp}
          placeholder="Enter OTP"
          onChange={(value) => value.length <= 6 && setOtp(value)}
          type="number"
          label="OTP"
        />
        <Button
          label="Verify OTP"
          onClick={handleVerifyOtp}
          disabled={!(otp.length === 6)}
        />
      </div>
    </>
  );
};

export default VerifyOtp;
