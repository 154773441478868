import axiosInstance from "../axios/AxiosInstance";
import { createWarehouseType } from "./type";

export const createWarehouse = async (warehouse: createWarehouseType) => {
    try {
        const response = await axiosInstance.post("/warehouse/create-warehouse", warehouse);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const updateWarehouse = async (id: string, warehouse: createWarehouseType) => {
    try {
        const response = await axiosInstance.put(`/warehouse/update-warehouse/${id}`, warehouse);   
        return response.data;     
    } catch (error) {
        return error;
    }
}

export const getWarehouse = async () => {
    try {
        const response = await axiosInstance.get("/warehouse/get-all");
        return response.data;
    } catch (error) {
        return error;
    }
}