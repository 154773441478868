import axios from "axios";
import { getItem } from "../utils/localStorage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json'

    const user = getItem("user") as { token?: string };
    const accessToken = user?.token;
    if(accessToken) {
        config.headers['Authorization'] = `${accessToken}`        
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
