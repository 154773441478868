import React from "react";
import RecentOrders from "./RecentOrders";
import LoadingTrucks from "./LoadingTrucks";
import LatestShipping from "./LatestShipping";
import UnloadingCargo from "./UnloadingCargo";
import AvailableCargo from "./AvailableCargo";
import OrderRequests from "./OrderRequests";

const Home = () => {
  return (
    <>
      <div className="flex gap-3 w-full">
        <div className="flex w-3/4 flex-col gap-3">
          <div className="flex gap-3 w-full">
            <RecentOrders />
            <LoadingTrucks />
          </div>
          <div>
            <LatestShipping />
          </div>
          <div className="flex gap-3 w-full">
            <UnloadingCargo />
            <AvailableCargo />
          </div>
        </div>
        <div className="w-1/4">
          {/* <OrderRequests /> */}
          <OrderRequests />
        </div>
      </div>
    </>
  );
};

export default Home;
