import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import { LoaderProvider } from "./context/LoderContext";
import Loader from "./components/common/Loader";
import { getItem } from "./utils/localStorage";
import Dashboard from "./pages/dashboard";
import Header from "./components/common/Header";
import ForgotPassword from "./pages/forgot-password";

function App() {
  const [authToken, setAuthToken] = useState<string | null>(null);
  
  const hideHeaderPath = ["login", "forgot-password", "reset-password"];
  const path = !hideHeaderPath.some(route => window.location.pathname.includes(route));
  useEffect(() => {
    const userData = getItem("user") as { token?: string } | null;
    setAuthToken(userData?.token ?? null);
  }, []);

  return (
    <LoaderProvider>
      <BrowserRouter>
      {path && <Header />}
      <Loader />
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          {authToken ? (
            <Route
              path="/login"
              element={<Navigate to="/dashboard" replace={true} />}
            />
          ) : (
            <Route path="/login" element={<Login />} />
          )}
          {
            authToken ? (
              <Route
                path="/forgot-password"
                element={<Navigate to="/dashboard" replace={true} />}
              />
            ) : (
              <Route path="/forgot-password" element={<ForgotPassword />} />
            )
          }
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {authToken && <Route path="/dashboard" element={<Dashboard />} />}
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </BrowserRouter>
    </LoaderProvider>
  );
}

export default App;
