import React from "react";
import ExitIcon from "../../../../assets/icons/exit.svg";
import SectionHeader from "../../../../components/common/SectionHeader";
import { availableCargoData } from "../../../../utils/staticData";
import { ImageWithBorder } from "../../../../components/common/ImageWithBorder";


const AvailableCargo = () => {
  return (
    <div className="bg-white w-1/2 px-4 pb-3 rounded-lg">
      <SectionHeader title="Available Cargo" isViewAll showBorder />
      {availableCargoData.map((cargo, index) => (
        <>
          <div className="flex items-center gap-7 mt-2" key={index}>
            <h1 className="text-xs text-black opacity-65 uppercase leading-6">
              {cargo.name}
            </h1>
            <div className="flex items-center gap-2">
              <div className="w-16 bg-[#D9D9D9] rounded-full h-2.5">
                <div
                  className="bg-[#4096FF] h-2.5 rounded-full"
                  style={{ width: `${cargo.availability}%` }}
                ></div>
              </div>
              <h1 className="text-xs text-[#003EB3] font-medium leading-6">
                {cargo.availability}%
              </h1>
            </div>
            <div className="flex flex-row items-center gap-3">
              <h1 className="text-xs leading-6 w-14 text-ellipsis overflow-hidden">{cargo.source}</h1>
              <div className="border-2 border-[#003EB3] w-10 mt-1"></div>
              <h1 className="text-xs leading-6 w-14 text-ellipsis overflow-hidden">{cargo.destination}</h1>
            </div>
            <ImageWithBorder src={ExitIcon} alt="Exit icon" isBorder />
          </div>
        </>
      ))}
    </div>
  );
};

export default AvailableCargo;
