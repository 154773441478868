import axiosInstance from "../axios/AxiosInstance";

export const login = async (email: string, password: string) => {
    try {
        const response = await axiosInstance.post("/user/login", {email, password});
        return response.data;
    } catch (error) {
        return error;        
    }
}

export const forgotPassword = async (email: string) => {
    try {
        const response = await axiosInstance.post("/user/resend-otp", {email});
        return response.data;
    } catch (error) {
        return error;       
    }
}

export const verifyOtp = async (email: string | undefined, otp: string) => {
    try {
        const response = await axiosInstance.post("/user/verify-otp", {email, otp});
        return response.data;
    } catch (error) {
        return error;        
    }
}

export const resetPassword = async (email: string, password: string) => {
    try {
        const response = await axiosInstance.post("/user/reset-password", {email, password});
        return response.data;
    } catch (error) {
        return error;
    }
}