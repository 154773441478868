interface InputProps {
        value: string;
        onChange: (value: string) => void;
        type: string;
        label?: string;
        placeholder: string;
        isLabel?: boolean;
        className?: string;
        isDisabled?: boolean;
        isVehicleNumber?: boolean;
}

const Input = ({ value, onChange, type, label, placeholder, isLabel, className, isDisabled, isVehicleNumber } : InputProps) => {
  return (
    <div className={`flex flex-col gap-1 ${className}`}>
        {isLabel && <span className="text-sm font-medium capitalize">{label}</span>}
        <input
            className={`h-10 outline-none px-3 border border-solid border-gray-400 rounded-lg `}
            type={type}
            placeholder={placeholder}
            name={label}
            value={value}
            onChange={isVehicleNumber ? (e) => {
              const input = e.target.value.toUpperCase();
              const formatted = input
                .replace(/[^A-Z0-9]/g, '')
                .match(/([A-Z]{0,2})(\d{0,2})([A-Z]{0,2})(\d{0,4})/);
              let vehicleNumber = '';
              if (formatted) {
                vehicleNumber =
                  (formatted[1] ? formatted[1] + ' ' : '') +
                  (formatted[2] ? formatted[2] + ' ' : '') +
                  (formatted[3] ? formatted[3] + ' ' : '') +
                  (formatted[4] || '');
              }
              onChange(vehicleNumber.trim());
            } :(e) => onChange(e.target.value)}
            disabled={isDisabled}
        />
    </div>
  )
}

export default Input