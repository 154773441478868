import React from "react";
import { ImageWithBorder } from "../../../components/common/ImageWithBorder";
import { sidebarIcon } from "../../../utils/staticData";

interface SidebarProps {
  setActivePage: (value: string) => void;
  activePage: string | undefined;
}

const Sidebar = ({setActivePage, activePage}: SidebarProps) => {
  return (
    <>
    <div className="w-20 h-[calc(100vh-70px)] border-x border-[#D9D9D9] sticky left-0 top-[70px] z-50">
      <div className="flex flex-col mt-10 items-center gap-4">
        {sidebarIcon.map((item, index) => (
          <ImageWithBorder
            src={item.icon}
            alt={item.name}
            key={index}
            onClick={() => {
              setActivePage(item.name);
              sessionStorage.setItem("active_page", item.name);
            }}
            isActive={activePage === item.name}
          />
        ))}
      </div>
    </div>
    </>
  );
};

export default Sidebar;
