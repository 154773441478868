// src/DropdownCheckbox.tsx
import React, { useEffect, useState } from "react";

interface Option {
  label: string;
  value: string;
}

interface DropdownCheckboxProps {
  options: Option[];
  label: string;
  selectedValue: string[];
  setSelectedValue: React.Dispatch<React.SetStateAction<string[]>>;
}

const DropdownCheckbox: React.FC<DropdownCheckboxProps> = ({
  options,
  label,
  selectedValue,
  setSelectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCheckboxChange = (value: string) => {
    setSelectedValue((prev: string[]) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  useEffect(() => {
    console.log(selectedValue, "checked");
  }, [selectedValue]);

  const filteredOptions = options
    .slice(1)
    .filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className="mt-4">
      {label && (
        <span className="text-sm font-medium capitalize ">{label}</span>
      )}
      <div className="relative inline-block w-full ">
        <div
          onClick={toggleDropdown}
          className="cursor-pointer py-2 px-4 border border-gray-400 rounded-md bg-white flex justify-between items-center"
        >
          <span>{label}</span>
          <span>{isOpen ? "▲" : "▼"}</span>
        </div>
        {isOpen && (
          <div className="w-full left-0 top-full mt-2 border border-gray-400 rounded-md overflow-hidden bg-white z-10">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 border-b border-gray-300 w-full focus:outline-none"
            />
            <div className="max-h-48 overflow-y-auto">
              {filteredOptions.map((option) => (
                <label
                  key={option.value}
                  className="block p-2 hover:bg-gray-100"
                >
                  <input
                    type="checkbox"
                    value={option.value}
                    checked={selectedValue.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                    className="mr-2"
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownCheckbox;
