import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import Route from './components/Route';

const Dashboard = () => {
  const [activePage, setActivePage] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    const currentPage = sessionStorage.getItem('active_page');
    setActivePage((!currentPage || currentPage === "Dashboard") ? "Dashboard" : currentPage)
  }, [])

  const getActivePage = () => {
    switch (activePage) {
      case "Dashboard":
        return <Home />
      case "Route": 
        return <Route />
      default:
        return <div>
          <h1 className='text-black'>404</h1>
          <p className='text-black'>Page not found</p>
        </div>
    }
  }

  return (
      <div className='flex h-full w-full'>
        <Sidebar setActivePage={setActivePage} activePage={activePage}/>
        <div className='w-full px-7 py-2 bg-black bg-opacity-10'>
          {getActivePage()}
        </div>  
      </div>
  )
}

export default Dashboard