import React, { useEffect } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal = (props: ModalProps) => {
  const { isOpen, onClose, children } = props;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose?.();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.65)] flex items-center overflow-y-auto justify-center z-[90]">
        {children}
    </div>
  );
};

export default Modal;
