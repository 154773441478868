import PieChart from "../../../../components/common/PieChart";
import SectionHeader from "../../../../components/common/SectionHeader";
import { truckData } from "../../../../utils/staticData";

const LoadingTrucks = () => {
  return (
    <div className="bg-white w-1/2 px-4 py-3 rounded-lg">
      <SectionHeader title="Loading Trucks" isViewAll showBorder />
      <div className="flex gap-10 items-center">
        <PieChart data={truckData}/>
        <div className="grid grid-cols-2 gap-7">
          {
            truckData.map((data, index) => (
              <div key={index} className="flex items-center">
                <div className={`border-[3px] h-full mr-2`} style={{borderColor: `${data.color}`}}/>
                <div>
                  <h1 className="text-xs text-black leading-6 opacity-65">{data.label}</h1>
                  <h1 className="text-base text-black font-semibold opacity-85 leading-6">{data.quantity}</h1>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default LoadingTrucks;
