import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

import location from "../../assets/icons/map_location.svg";

interface Props {
  arrivedLatitude: number;
  arrivedLongitude: number;
  destinationLatitude: number;
  destinationLongitude: number;
  showRouting?: boolean;
  setRouteInfo?: (value: any) => void;
}

const Map: React.FC<Props> = ({
  arrivedLatitude,
  arrivedLongitude,
  destinationLatitude,
  destinationLongitude,
  showRouting,
  setRouteInfo
}: Props) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = L.map(mapContainerRef.current, {
        zoomControl: false,
        attributionControl: false,
      }).setView([destinationLatitude, destinationLongitude], 13);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 7,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      const defaultIcon = L.icon({
        iconUrl: location,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      });

      const arrivedCoordinates: [number, number] = [
        arrivedLatitude,
        arrivedLongitude,
      ];
      const destinationCoordinates: [number, number] = [
        destinationLatitude,
        destinationLongitude,
      ];

      const arrivedMarker = L.marker(arrivedCoordinates, {
        icon: defaultIcon,
      }).addTo(map);
      const destinationMarker = L.marker(destinationCoordinates, {
        icon: defaultIcon,
      }).addTo(map);

      arrivedMarker.on("click", () => {
        map.setView([arrivedLatitude, arrivedLongitude], 17);
      });

      destinationMarker.on("click", () => {
        map.setView([destinationLatitude, destinationLongitude], 17);
      });

      if (showRouting) {
        const routingControl = L.Routing.control({
          waypoints: [
            L.latLng(arrivedLatitude, arrivedLongitude),
            L.latLng(destinationLatitude, destinationLongitude),
          ],
          routeWhileDragging: true,
          // draggableWaypoints: false,
          router: new L.Routing.OSRMv1({
            serviceUrl: "https://router.project-osrm.org/route/v1",
          }),
          show: false,
          lineOptions: {
            styles: [{ color: "#1677FF", weight: 5 }],
            extendToWaypoints: false,
            missingRouteTolerance: 10,
          },
        }).addTo(map);

        routingControl.on("routesfound", (e) => {
          const routes = e.routes;

        const minDistanceRoute = routes.reduce((minRoute: any, currentRoute: any) => {
            return currentRoute.summary.totalDistance < minRoute.summary.totalDistance ? currentRoute : minRoute;
        });

        const routeDetails = {
          name: minDistanceRoute.name,
          distance: `${(minDistanceRoute.summary.totalDistance / 1000).toFixed(2)} Km`,
          time: `${Math.floor(minDistanceRoute.summary.totalTime / 3600)} hours ${Math.floor((minDistanceRoute.summary.totalTime % 3600) / 60)} minutes`,
        }

        if (setRouteInfo) {
          setRouteInfo(routeDetails);
        }

          console.log("Min Distance Route", routeDetails);
          console.log("Routing Information: ", routes);
          console.log("Distance: ", routeDetails.distance);
          console.log("Travel Time: ", routeDetails.time);
        });

        

        routingControl.getPlan().on("waypointdragstart", (e) => {
          (e as L.LeafletMouseEvent).originalEvent.preventDefault();
        });

        routingControl.getPlan().on("waypointdrag", (e) => {
          (e as L.LeafletMouseEvent).originalEvent.preventDefault();
        });
      }

      return () => {
        map.remove();
      };
    }
  }, []);

  return (
    <div ref={mapContainerRef} style={{ width: "100%", height: "100%", zIndex: 10 }} />
  );
};

export default Map;
