export const setItem = <T>(key: string, value: T): void => {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };
  
  export const getItem = <T>(key: string): T | null => {
    if (typeof window !== "undefined") {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) as T : null;
    }
    return null;
  };
  
  export const removeItem = (key: string): void => {
    if (typeof window !== "undefined") {
      localStorage.removeItem(key);
    }
  };
  
  export const clearStorage = (): void => {
    if (typeof window !== "undefined") {
      localStorage.clear();
    }
  };
  