interface ButtonProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

const Button = ({label, onClick, disabled, className}: ButtonProps) => {
  return (
    <button
        className={`w-full mt-5 py-3 bg-[#1677FF] text-sm font-medium leading-5 text-white uppercase rounded-md ${disabled && "opacity-40 cursor-pointer"} ${className}`}
        onClick={onClick}
        disabled={disabled}
        >
        {label}
    </button>
  )
}

export default Button