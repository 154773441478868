import axiosInstance from "../axios/AxiosInstance";
import { createRouteType } from "./type";

export const createRoute = async (route: createRouteType) => {
    try {
        const response = await axiosInstance.post("/route/create", route);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getRouteWaypoints = async (warehouseId: string) => {
    try {
        const response = await axiosInstance.get(`/route/get-route-waypoints/${warehouseId}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const updateRoute = async (routeId: string, payload: any) => {
    try {
        const response = await axiosInstance.put(`/route/update/${routeId}`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getAllRoutes = async () => {
    try {
        const response = await axiosInstance.get("/route/all");
        return response.data;
    } catch (error) {
        return error;
    }
}