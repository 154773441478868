import React from "react";
import SectionHeader from "../../../../components/common/SectionHeader";
import { latestShippingData } from "../../../../utils/staticData";
import { statusBgColor } from "../../../../utils/statusBgColor";

const LatestShipping = () => {
  return (
    <div className="bg-white px-6 py-4 rounded-lg shadow-md">
      <SectionHeader title="Latest Shipping" isViewAll showBorder />
      <div className="flex items-center justify-between mt-4">
        <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/6 px-2">
          Order ID
        </h1>
        <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/6 px-2">
          Status
        </h1>
        <div className="flex items-center justify-between w-3/4">
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/3 px-8">
            Customer
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/5 px-2">
            Departure
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/5 px-2">
            Weight
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/5 px-2">
            Arrival
          </h1>
          <h1 className="text-xs text-gray-600 font-semibold uppercase w-1/5 px-2">
            Arrival Date
          </h1>
        </div>
      </div>
      <hr className="my-3 border-gray-200" />
      {latestShippingData.map((shipping, index) => (
        <div key={index}>
          <div className="flex items-center justify-between mt-2">
            <h1 className="text-xs text-black opacity-65 uppercase leading-6 w-1/6 px-2">
              {shipping.orderId}
            </h1>
            <div
              className={`text-xs border text-center px-3 py-1 bg-opacity-30 rounded-md w-1/6 ${statusBgColor(
                shipping.status
              )}`}
            >
              {shipping.status}
            </div>
            <div className="flex items-center justify-between w-3/4">
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/3 px-8">
                {shipping.customer}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/5 px-2">
                {shipping.departure}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/5 px-2">
                {shipping.weight}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/5 px-2">
                {shipping.arrival}
              </h1>
              <h1 className="text-xs leading-6 text-ellipsis overflow-hidden w-1/5 px-2">
                {shipping.arrivalDate}
              </h1>
            </div>
          </div>
          {index !== latestShippingData.length - 1 && (
            <hr className="my-3 border-gray-200" />
          )}
        </div>
      ))}
    </div>
  );
};

export default LatestShipping;
