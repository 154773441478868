import axiosInstance from "../axios/AxiosInstance";
import { addVehicleType } from "./type";

export const createVehicle = async (vehicle: addVehicleType) => {
    try {
        const response = await axiosInstance.post("/vehicle/add-vehicle", vehicle);
        return response.data;
    } catch (error) {
        return error;
    }
}