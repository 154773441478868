import React, { useEffect, useState } from "react";
import Input from "./Input";
import downArrow from "../../assets/icons/downArrow.svg";
import filerOutlined from "../../assets/icons/filterOutlined.svg";
import profileImage from "../../assets/icons/profile.svg";

import logo from "../../assets/icons/logo.svg";
import { ImageWithBorder } from "./ImageWithBorder";
import { getItem } from "../../utils/localStorage";
import { businessModalData, headerIcon } from "../../utils/staticData";
import BusinessModal from "./BusinessModal";
import AddWarehouse from "../../modal/AddWarehouse";
import CreateRoute from "../../modal/CreateRoute";
import CreateCargo from "../../modal/CreateCargo";

interface User {
  first_name?: string;
  last_name?: string;
  role?: string;
}

const Header = () => {
  const [userData, setUserData] = useState<{
    first_name?: string;
    last_name?: string;
    role: string;
  }>({ role: "" });

  const [openAddnewBusinessModal, setOpenAddNewBusinessModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");

  useEffect(() => {
    const user : User = getItem("user") || {};
    setUserData({ ...user, role: user.role || "" });
  }, []);

  const name = (userData?.first_name || "") + " " + (userData?.last_name || "");
  return (
    <>
      {openAddnewBusinessModal && (
        <BusinessModal onClose={() => setOpenAddNewBusinessModal(false)}>
          <div className="w-fit h-full p-4 bg-white">
            <div className="grid grid-cols-2 gap-4">
              {businessModalData.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col w-[167px] h-[158px] p-5 border border-[#D9D9D9] items-center rounded-lg cursor-pointer"
                  onClick={() => {
                    setOpenModal(true)
                    setSelectedBusiness(item.alt)
                  }}
                >
                  <ImageWithBorder
                    src={item.icon}
                    alt={item.alt}
                    className="w-16 h-16"
                    isActive
                  />
                  <h1 className="text-sm mt-2 font-medium leading-6 uppercase">
                    {item.title}
                  </h1>
                  <h1 className="text-[10px] mt-1 text-center text-black opacity-45 leading-3">
                    {item.description}
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </BusinessModal>
      )}
      {selectedBusiness === "add_warehouse" && <AddWarehouse openModal={openModal} setIsOpen={setOpenModal}/> }
      {selectedBusiness === "create_route" && <CreateRoute openModal={openModal} setIsOpen={setOpenModal}/> }
      {selectedBusiness === "add_cargo" && <CreateCargo openModal={openModal} setIsOpen={setOpenModal}/> }
      <div className="flex w-full h-[70px] bg-white border-y border-solid border-[#D9D9D9] py-3 pl-1 pr-7 justify-between z-20 sticky left-0 bottom-0 top-0">
        <div className="flex flex-row gap-4 items-center">
          <img src={logo} alt="logo" className="w-20" />
          <Input
            value=""
            onChange={() => {}}
            type="text"
            label="Search"
            placeholder="Search by user id, user name, data etc."
            className="w-[360px]"
          />
          <ImageWithBorder src={filerOutlined} alt="filter" isBorder />
        </div>
        <div className="flex flex-row gap-4 items-center">
          {headerIcon.map((item, index) => (
            <ImageWithBorder
              src={item.icon}
              alt={item.alt}
              onClick={() =>
                item.alt === "plusIcon" &&
                setOpenAddNewBusinessModal(!openAddnewBusinessModal)
              }
              key={index}
              isBorder
            />
          ))}
          <ImageWithBorder src={profileImage} alt="profile image" isBorder />
          <div className="">
            <h1 className="text-sm font-medium leading-6">{name}</h1>
            <h2 className="text-xs font-normal text-black opacity-60 leading-6">
              {userData?.role}
            </h2>
          </div>
          <img width={16} height={16} src={downArrow} alt="down arrow" />
        </div>
      </div>
    </>
  );
};

export default Header;
