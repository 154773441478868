interface imageProps {
    src: string;
    alt: string;
    onClick?: () => void;
    isBorder?: boolean;
    isActive?: boolean;
    className?: string;
  }

export const ImageWithBorder = ({ src, alt, onClick, isBorder, isActive, className }: imageProps) => {
    return (
      <div
        key={alt}
        className={`flex h-10 w-10 p-2.5 ${isBorder ? "border border-gray-400 rounded-lg" : "rounded-full"} justify-center cursor-pointer 
                      hover:bg-[#E6F4FF] hover:border-[#1677FF] hover:text-[#1677FF] ${isActive ? "bg-[#E6F4FF]" : ""} ${className}`}
        onClick={onClick}
      >
        <img src={src} alt={alt} />
      </div>
    );
  };