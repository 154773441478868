import React, { useEffect, useState } from "react";
import SectionHeader from "../../../../components/common/SectionHeader";
import Map from "../../../../components/common/Map";
import { getAllRoutes } from "../../../../service/route";
import { ImageWithBorder } from "../../../../components/common/ImageWithBorder";
import fileIcon from "../../../../assets/icons/file.svg";
import historyIcon from "../../../../assets/icons/history.svg";
import CreateRoute from "../../../../modal/CreateRoute";

const Route = () => {
  const [routes, setRoutes] = useState<any>([]);
  const [openCreateRouteModal, setOpenCreateRouteModal] = useState(false);

  const getRoutes = async () => {
    const res = await getAllRoutes();
    setRoutes(res.data);
  };

  useEffect(() => {
    getRoutes();
  }, []);

  const handleClick = () => {
    setOpenCreateRouteModal(true);
  };

  return (
    <>
      {openCreateRouteModal && <CreateRoute openModal={openCreateRouteModal} setIsOpen={setOpenCreateRouteModal} />}
      <div className="grid grid-cols-[70%_30%] h-full gap-3">
        <div className=" flex flex-col h-full bg-white overflow-hidden rounded-lg">
          <div className="px-4 pb-3">
            <SectionHeader
              title="Route Planning"
              isButton
              onClick={() => handleClick()}
            />
          </div>
          <div className="relative bg-white h-full">
            <Map
              arrivedLatitude={23.0225}
              arrivedLongitude={72.5714}
              destinationLatitude={19.076}
              destinationLongitude={72.8777}
              showRouting
            />
            <div className="absolute top-0 left-0 h-full w-[350px] p-3 z-20">
              <div className="bg-white rounded-lg h-full w-full">Lwft</div>
            </div>
            <div className="absolute bottom-0 left-[350px] right-0 w-auto h-40 max-h-40 pr-3 pb-3 z-20">
              <div className="flex bg-white rounded-lg h-full">
                <div className="w-1/2 p-2">
                  <div className="flex justify-between">
                    <span>Departure</span>
                    <span>Ahmedabad</span>
                  </div>
                  {/* {
                  routes?.waypoints.map((waypoint: any) => (
                   <></>
                  ))
                } */}
                  <div className="flex justify-between">
                    <span>Arrival</span>
                    <span>Mumbai</span>
                  </div>
                </div>
                <hr className="border border-1 border-black bg-white h-full opacity-5" />
                <div className="w-1/2 p-2">dgt</div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[calc(100vh-85px)] bg-white rounded-lg overflow-y-scroll">
          <div className="px-4">
            <SectionHeader title="Routes" isInput showBorder />
            <div className="flex items-center justify-between">
              <h1 className="text-sm text-black opacity-65 leading-6">
                Routes
              </h1>
              <div className="flex gap-1">
                <ImageWithBorder src={fileIcon} alt="file" isBorder />
                <ImageWithBorder src={historyIcon} alt="history" />
              </div>
            </div>
           {routes.map(
              (
                route: { _id: string; diparture: string; destination: string },
                index: number
              ) => (
                <div key={index} className="flex flex-col py-4">
                  <div className="flex w-full items-center">
                    <h1 className="text-sm leading-6 mr-2">{route?._id}</h1>
                    <hr className="border border-1 border-black bg-white w-full opacity-45" />
                  </div>
                  <div className="flex flex-row justify-between">
                    <h1 className="text-xs leading-6 text-gray-400">
                      {route.diparture} - {route.destination}
                    </h1>
                    <h1 className="text-xs leading-6 text-[#1677FF]">
                      View More
                    </h1>
                  </div>
                </div>
              )
            )}
           </div>
          </div>
      </div>
    </>
  );
};

export default Route;
