import React, { useEffect, useState } from "react";
import Modal from "../components/common/Modal";
import Dropdown from "../components/common/Dropdown";
import Input from "../components/common/Input";
import Button from "../components/common/Button";
import { getAllRoutes } from "../service/route";
import { useLoader } from "../context/LoderContext";
import { getAllDrivers } from "../service/driver";
import { createVehicle } from "../service/vehicle";

interface CreateCargoProps {
  openModal: boolean;
  setIsOpen: (value: boolean) => void;
}

const CreateCargo = (props: CreateCargoProps) => {
  const { openModal, setIsOpen } = props;
  const [formData, setFormData] = useState<{
    route: string;
    number: string;
    driver: string;
    capacity: string;
    name: string;
    model: string;
  }>({
    route: "",
    number: "",
    driver: "",
    capacity: "",
    name: "",
    model: "",
  });
  const [routeData, setRouteData] = useState<any[]>([]);
  const [driversData, setDriversData] = useState([]);
  const { showLoader, hideLoader } = useLoader();

  const isFormComplete = () => {
    return Object.values(formData).every((field) => field.trim() !== "");
  };

  const handleSubmit = async () => {
    showLoader();
    const vehicle = await createVehicle(formData);
    hideLoader();
    if(vehicle.code === 200) {
      setIsOpen(false);
    }
    console.log(formData);
  }

  const getRoutes = async () => {
    showLoader();
    const res = await getAllRoutes();
    if(res.code === 200) {
      const filteredRoutesData = res.data.map(
        (route: {diparture: string, destination: string, _id: string}) => ({
          label: route.diparture + " - " + route.destination,
          value: route._id,
        })
      )

      if (filteredRoutesData.length > 0) {
        filteredRoutesData.unshift({ label: "Select Route Name", value: "" });
      } else {
        filteredRoutesData.push({ label: "No Routes found", value: "" });
      }
      hideLoader();
      setRouteData(filteredRoutesData);
    }
  }

  const getDrivers = async () => {
    showLoader();
    const res = await getAllDrivers();
    if(res.code === 200){
      const filterdDriversData = res.data.map(
        (driver: { name: string; contact_number: string; _id: any; }) => ({
          label: driver.name + " (" + driver.contact_number + ")",
          value: driver._id
        })
      )

      if (filterdDriversData.length > 0) {
        filterdDriversData.unshift({ label: "Select Driver Name", value: "" });
      } else {
        filterdDriversData.push({ label: "No Drivers found", value: "" });
      }
      hideLoader();
      setDriversData(filterdDriversData)
    }
  }

  useEffect(() => {
    getRoutes();
    getDrivers();
  }, [])

  return (
    <>
      <Modal isOpen={openModal} onClose={() => setIsOpen(false)}>
        <div className="w-[400px] max-h-4/5 bg-white rounded-md p-1">
          <div className="w-full h-full p-4 overflow-y-scroll">
            <Input
              value={formData.number}
              onChange={(value) =>
                setFormData({ ...formData, number: value })
              }
              type="text"
              label="Vehicle Number"
              placeholder="Enter Vehicle Number (Eg. GJ 01 AB 1234)"
              className="w-full mt-4"
              isLabel
              isVehicleNumber
            />
            <Input
              value={formData.name}
              onChange={(value) =>
                setFormData({ ...formData, name: value })
              }
              type="text"
              label="Vehicle Name"
              placeholder="Enter Vehicle Name"
              className="w-full mt-4"
              isLabel
            />
            <Input
              value={formData.model}
              onChange={(value) =>
                setFormData({ ...formData, model: value })
              }
              type="text"
              label="Vehicle Model"
              placeholder="Enter Vehicle Model"
              className="w-full mt-4"
              isLabel
            />
            <Input
              value={formData.capacity}
              onChange={(value) =>
                setFormData({ ...formData, capacity: value })
              }
              type="number"
              label="Vehicle Load (ton)"
              placeholder="Enter Vehicle Load"
              className="w-full mt-4"
              isLabel
            />
            <Dropdown
              data={routeData}
              isBorder
              isLabel
              label="Select Route Name"
              setSelectValue={(value) =>
                setFormData((prevState) => ({ ...prevState, route: value }))
              }
              selectedValue={formData.route}
              // isDisabled={disableWarehouseDropdown}
            />
            <Dropdown
              data={driversData}
              isBorder
              isLabel
              label="Select Driver Name"
              setSelectValue={(value) =>
                setFormData((prevState) => ({ ...prevState, driver: value }))
              }
              selectedValue={formData.driver}
              // isDisabled={disableWarehouseDropdown}
            />
            <Button
              label="Create Cargo"
              onClick={() => handleSubmit()}
              disabled={!isFormComplete()}
              className="w-full mt-4"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateCargo;
