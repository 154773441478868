"use client";

import React, { useState } from "react";

type AreaChartProps = {
  data: { date: string; order: number }[];
  width: number;
  height: number;
  yAxisLabel: string;
};

const AreaChart: React.FC<AreaChartProps> = ({
    data,
    width,
    height,
    yAxisLabel,
  }) => {
    const [tooltip, setTooltip] = useState<{
      x: number;
      y: number;
      date: string;
      order: number;
    } | null>(null);
    const maxorder = Math.max(...data.map((d) => d.order)) + 100;
    const maxX = data.length - 1;
    const lastDay = data.length;
  
    const getCoordinates = () => {
      return data
        .map((point, index) => {
          const x = (index / maxX) * width;
          const y = height - (point.order / maxorder) * height;
          return `${x},${y}`;
        })
        .join(" ");
    };
  
    const getFillCoordinates = () => {
      const points = getCoordinates();
      return `0,${height} ${points} ${width},${height} 0,${height}`;
    };
  
    const handleMouseOver = (
      event: React.MouseEvent,
      point: { date: string; order: number }
    ) => {
      setTooltip({
        x: event.pageX,
        y: event.pageY,
        date: point.date,
        order: point.order,
      });
    };
  
    const handleMouseOut = () => {
      setTooltip(null);
    };
  
    return (
      <div className="bg-white border w-fit border-[#F0F0F0] rounded-xl">
        <svg
          width={width + 50}
          height={height + 20}
          viewBox={`0 0 ${width - 35} ${height + 20}`}
          style={{ padding: "10px" }}
        >
          {/* Y-axis date */}
          <text
            x="-30"
            y={height / 2 - 7}
            fontSize="12"
            fontWeight="500"
            textAnchor="middle"
            transform={`rotate(-90, -30, ${height / 2})`}
            fill="#333"
          >
            {yAxisLabel}
          </text>
  
          {/* Horizontal grid lines and left-side order dates */}
          {[0, 1, 2, 3, 4].map((i) => {
            const order = (i * maxorder) / 4;
            const y = height - (i * height) / 4;
            return (
              <g key={i}>
                <line
                  x1="0"
                  y1={y}
                  x2={width}
                  y2={y}
                  stroke="#e0e0e0"
                  strokeWidth="1"
                />
                <text x="-5" y={y + 7} fontSize="10" textAnchor="end" fill="#000000" opacity={0.45}>
                  {Math.round(order)}
                </text>
              </g>
            );
          })}
  
          {/* Vertical grid lines */}
          {data.map((point, index) => {
            const x = (index / maxX) * width;
            const showLastLine =
              index === maxX &&
              (lastDay === 28 || lastDay === 29 || lastDay === 30);
            // Only render the vertical line if it's every 5th entry
            return (
              (index % 5 === 0 || showLastLine) && (
                <line
                  key={index}
                  x1={x}
                  y1="0"
                  x2={x}
                  y2={height}
                  stroke="#e0e0e0"
                  strokeWidth="1"
                />
              )
            );
          })}
  
          {/* X-axis dates: Show every 5th date */}
          {data.map((point, index) => {
            const x = (index / maxX) * width;
            const lastDate =
              index === maxX &&
              (lastDay === 28 || lastDay === 29 || lastDay === 30);
            return (
              (index % 5 === 0 || lastDate) && (
                <text
                  key={index}
                  x={x}
                  y={height + 20}
                  fontSize="10"
                  textAnchor="middle"
                  fill="#000000"
                  opacity={0.45}
                >
                  {point.date}
                </text>
              )
            );
          })}
  
          {/* Area fill */}
          <polygon points={getFillCoordinates()} fill="rgba(66, 165, 245, 0.5)" />
  
          {/* Line chart */}
          <polyline
            points={getCoordinates()}
            fill="none"
            stroke="#42a5f5"
            strokeWidth="2"
          />
  
          {/* Data points */}
          {data.map((point, index) => {
            const x = (index / maxX) * width;
            const y = height - (point.order / maxorder) * height;
            return (
              <g key={index}>
                <circle
                  cx={x}
                  cy={y}
                  r="3"
                  fill="#42a5f5"
                  onMouseOver={(e) => handleMouseOver(e, point)}
                  onMouseOut={handleMouseOut}
                />
              </g>
            );
          })}
        </svg>
        {tooltip && (
          <div
            style={{
              position: "absolute",
              top: tooltip.y - 40,
              left: tooltip.x + 10,
              backgroundColor: "white",
              opacity: 0.8,
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #e0e0e0",
              fontSize: "12px",
              fontWeight: "bold",
              pointerEvents: "none",
            }}
          >
            <h1>Date : {tooltip.date}</h1>
            <h1>Order : {tooltip.order}</h1>
          </div>
        )}
      </div>
    );
  };

export default AreaChart;