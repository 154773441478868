interface DropdownProps {
  data: { label: string, value?: string }[];
  isBorder?: boolean;
  isLabel?: boolean;
  label?: string;
  setSelectValue: (value: string) => void;
  selectedValue: string;
  isDisabled?: boolean;
}

const Dropdown = ({ data, isBorder, isLabel, label, setSelectValue, selectedValue, isDisabled }: DropdownProps) => {
  return (
    <div>
      {isBorder ? (
        <div className="mt-4">
          {isLabel && <span className="text-sm font-medium capitalize">{label}</span>}
          <div className="px-3 border border-gray-400 border-solid rounded-lg">
            <select
              name="select"
              id="dropdown"
              className="outline-none h-10 w-full"
              value={selectedValue}
              onChange={(e) => setSelectValue(e.target.value)}
              disabled={isDisabled}
            >
              {data.map((item, index) => (
                <option key={index} value={item.value} style={{height: "100px"}}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <>
          <select name="select" id="dropdown" className="outline-none">
            {data.map((item, index) => (
              <option key={index} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};

export default Dropdown;
