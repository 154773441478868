"use client";
import { useEffect, useState } from "react";
// import { loginUser } from "../service/auth";
import "../../App.css";
// import Loader from "../components/common/Loader";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { getItem, setItem } from "../../utils/localStorage";
import { useLoader } from "../../context/LoderContext";
import { login } from "../../service/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const navigate = useNavigate();

  const { hideLoader, showLoader } = useLoader();

  useEffect(() => {
    const user = getItem("user") as { token: string } | null;
    if (user && user.token) {
      navigate("/dashboard");
    }
  }, []);

  const handleLogin = async () => {
      showLoader();
    if (!email || !password) {
      setLoginMessage("Please fill in all fields!");
      setShowAlert(true);
      return;
    }
    const response = await login(email, password);
    hideLoader();
    if (response?.code === 200) {
      setLoginMessage(response.message);
      setShowAlert(true);
      setItem("user", response.data);
      window.location.href = "/dashboard";
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      {/* <AlertBadge
        bgColor={"bg-green-500"}
        message={loginMessage}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      /> */}
      <div className="grid grid-cols-[65%_35%] h-screen">
        <div className="background-image"></div>
        <div className="bg-white h-full content-center">
          <div className="w-full px-16 rounded-md opacity-90">
            <Input
              value={email}
              onChange={setEmail}
              placeholder="Enter email"
              type="email"
              label="Email"
            />
            <Input
              className="mt-5"
              value={password}
              placeholder="Enter password"
              onChange={setPassword}
              type="password"
              label="password"
            />
            <Button label="Login" onClick={handleLogin} />
            <div className="mt-2 text-end text-blue-700">
              <Link to={"/forgot-password"}>Forgot Password?</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
