export const statusBgColor = (status: string) => {
    switch (status) {
      case "Delivered":
        return "bg-[#ADC6FF] border-[#ADC6FF] text-[#2F54EB] ";
      case "Canceled":
        return "bg-[#FFA39E] border-[#FF6666] text-[#F5222D]";
      case "Active":
        return "bg-[#B7EB8F] border-[#7CB305] text-[#52C41A]";
      default:
        return "bg-white";
    }
};