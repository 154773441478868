import React, { useEffect } from "react";
import Modal from "../components/common/Modal";
import Input from "../components/common/Input";
import Button from "../components/common/Button";
import Dropdown from "../components/common/Dropdown";
import VerifyOtp from "../components/common/VerifyOtp";
import { createWarehouse, updateWarehouse } from "../service/warehouse";
import { useLoader } from "../context/LoderContext";

interface AddWarehouseProps {
  openModal: boolean;
  setIsOpen: (value: boolean) => void;
}

const countryData = [
  { label: "Bharat", value: "Bharat" },
  { label: "USA", value: "USA" },
];

const AddWarehouse = (props: AddWarehouseProps) => {
  const { openModal, setIsOpen } = props;
  const [formData, setFormData] = React.useState({
    warehouseName: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    latitude: "",
    longitude: "",
    contactNumber: "",
  });
  const [otp, setOtp] = React.useState("");
  const [OTPVerified, setOTPVerified] = React.useState(false);
  const [showOTPScreen, setShowOTPScreen] = React.useState(false);
  const [warehouseId, setWarehouseId] = React.useState("");

  const { showLoader, hideLoader } = useLoader();

  const handleSubmit = async () => {
    showLoader();
    const payload = {
      name: formData.warehouseName,
      address: {
        street: formData.street,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        pincode: formData.pinCode,
        latitude: formData.latitude,
        longitude: formData.longitude,
      },
      contactNumber: formData.contactNumber,
    };

    const response = await createWarehouse(payload);
    hideLoader();
    if (response.code === 200) {
      setWarehouseId(response.data._id);
      setShowOTPScreen(true);
    }
  };

  const isFormComplete = () => {
    return Object.values(formData).every((field) => field.trim() !== "");
  };

  const verifiyWarehouse = async () => {
    try {
      // await updateWarehouse(warehouseId, { is_verified: true });
      await updateWarehouse(warehouseId, { is_verified: true });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      country: prevState.country || countryData[0].label,
    }));
  }, []);

  useEffect(() => {
    if (OTPVerified) {
      verifiyWarehouse();
    }
  }, [OTPVerified]);

  return (
    <Modal isOpen={openModal && !OTPVerified} onClose={() => setIsOpen(false)}>
      {!showOTPScreen && !OTPVerified ? (
        <div className="w-[400px] h-4/5 bg-white rounded-md p-1">
          <div className="w-full h-full p-4 overflow-y-scroll">
            <Input
              value={formData.warehouseName}
              onChange={(value) =>
                setFormData({ ...formData, warehouseName: value })
              }
              type="text"
              label="Warehouse name"
              placeholder="Enter warehouser name"
              className="w-full"
              isLabel
            />
            <Input
              value={formData.street}
              onChange={(value) => setFormData({ ...formData, street: value })}
              type="text"
              label="Street"
              placeholder="Enter street"
              className="w-full mt-4"
              isLabel
            />
            <Dropdown
              data={countryData}
              isBorder
              isLabel
              label="Country"
              setSelectValue={(value) =>
                setFormData((prevState) => ({ ...prevState, country: value }))
              }
              selectedValue={formData.country}
            />
            <Input
              value={formData.state}
              onChange={(value) => setFormData({ ...formData, state: value })}
              type="text"
              label="State"
              placeholder="Enter state"
              className="w-full mt-4"
              isLabel
            />
            <Input
              value={formData.city}
              onChange={(value) => setFormData({ ...formData, city: value })}
              type="text"
              label="City"
              placeholder="Enter city"
              className="w-full mt-4"
              isLabel
            />
            <Input
              value={formData.pinCode}
              onChange={(value) => setFormData({ ...formData, pinCode: value })}
              type="number"
              label="Pin code"
              placeholder="Enter pin code"
              className="w-full mt-4"
              isLabel
            />
            <Input
              value={formData.latitude}
              onChange={(value) =>
                setFormData({ ...formData, latitude: value })
              }
              type="number"
              label="Latitude"
              placeholder="Enter latitude"
              className="w-full mt-4"
              isLabel
            />
            <Input
              value={formData.longitude}
              onChange={(value) =>
                setFormData({ ...formData, longitude: value })
              }
              type="number"
              label="Longitude"
              placeholder="Enter longitude"
              className="w-full mt-4"
              isLabel
            />
            <Input
              value={formData.contactNumber}
              onChange={(value) =>
                setFormData({ ...formData, contactNumber: value })
              }
              type="number"
              label="Contact number"
              placeholder="Enter contact number"
              className="w-full mt-4"
              isLabel
            />
            <Button
              label="Add Warehouse"
              onClick={handleSubmit}
              disabled={!isFormComplete()}
            />
          </div>
        </div>
      ) : (
        <div className="w-[400px] h-auto bg-white rounded-md px-4 py-6">
          <VerifyOtp
            otp={otp}
            setOtp={setOtp}
            setOTPVerified={setOTPVerified}
          />
        </div>
      )}
    </Modal>
  );
};

export default AddWarehouse;
