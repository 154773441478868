import React, { useState } from 'react'
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';
import VerifyOtp from '../../components/common/VerifyOtp';
import axiosInstance from '../../axios/AxiosInstance';
import { forgotPassword, resetPassword } from '../../service/auth';
import { useLoader } from '../../context/LoderContext';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [showOtp, setShowOtp] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [OTPVerified, setOTPVerified] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const { showLoader, hideLoader } = useLoader();
    const navigate = useNavigate();

    const handleForgotPassword = async () => {
        if(!email){
            setLoginMessage("Please enter your email!");
            setShowAlert(true);
            return;
        }
        showLoader();
        const response = await forgotPassword(email);
        hideLoader();
        if(response?.code === 200){
            setShowOtp(true);
            sessionStorage.setItem("email", email);
        }
        // const res = await forgotPassword(email);
        // setLoginMessage(res.message);
        // setShowAlert(true);
    }

    const handleResetPassword = async () => {
        if(!password || !confirmPassword){
            setLoginMessage("Please fill in all fields!");
            setShowAlert(true);
            return;
        }
        if(password !== confirmPassword){
            setLoginMessage("Passwords do not match!");
            setShowAlert(true);
            return;
        }
        const response = await resetPassword(email, password);
        if(response?.code === 200){
            setLoginMessage(response.message);
            setShowAlert(true);
            navigate("/login")
        }
        // const res = await resetPassword("patelchintan8358@gmail.com", password);
        // setLoginMessage(res.message);
        // setShowAlert(true);
        // if(res.code === 200){
        //     navigate.push("/login");
        // }
    }

    return (
        <>
            {/* <AlertBadge bgColor={"bg-green-500"} message={loginMessage} showAlert={showAlert} setShowAlert={setShowAlert}/> */}
            <div className="grid grid-cols-[65%_35%] h-screen">
                <div className="background-image">

                </div>
                <div className="bg-white h-full content-center px-16">
                   { !showOtp ?
                        <>
                            <Input 
                                value={email}
                                placeholder='Enter your email'
                                onChange={setEmail}
                                type="email"
                                label="Email"
                            />
                            <Button
                                label="Forgot Password"
                                onClick={handleForgotPassword}
                            />
                        </> : !OTPVerified ? <>
                            {/* <Input 
                                value={otp}
                                placeholder='Enter OTP'
                                onChange={setOtp}
                                type="text"
                                label="OTP"
                            />
                            <Button
                                label="Verify OTP"
                                onClick={handleVerifyOtp}
                            /> */}
                            <VerifyOtp otp={otp} setOtp={setOtp} setOTPVerified={setOTPVerified}/>
                        </> : <>
                            <Input 
                                value={password}
                                onChange={setPassword}
                                placeholder='Enter your password'
                                type="password"
                                label="password"
                            />
                            <Input 
                                className='mt-5'
                                value={confirmPassword}
                                onChange={setConfirmPassword}
                                placeholder='Confirm your password'
                                type="password"
                                label="confirm password"
                            />
                            <Button
                                label="Reset Password"
                                onClick={handleResetPassword}
                            />
                        </>
                    } 
                </div>
            </div>
        </>
    )
}

export default ForgotPassword