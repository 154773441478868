import { useState } from "react";
import { monthData, orderData, orderDetails } from "../../../../utils/staticData";
import SectionHeader from "../../../../components/common/SectionHeader";
import AreaChart from "../../../../components/common/AreaChart";
import task from "../../../../assets/icons/task.svg";

const RecentOrders = () => {

  const isProfit = true;

  const [selectedMonth, setSelectedMonth] = useState(monthData[0].label);
  
  return (
    <div className="bg-white w-1/2 px-4 py-3 rounded-lg">
      <SectionHeader title="Recent Orders" isDropdown dropdownData={monthData} setInputValue={setSelectedMonth} inptutValue={selectedMonth} showBorder />
      <div className="flex justify-between">
        <div className="flex flex-col gap-3">
          {
            orderDetails.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <img src={task} width={36} height={36} alt="" />
                <div>
                  <h1 className="text-xs text-black opacity-65 leading-6">
                    {item.label}
                  </h1>
                  <h2 className="text-sm text-black font-medium leading*6">{item.quantity}</h2>
                </div>
              </div>
            ))
          }
          <div>
            <h1 className="text-xs font-medium leading-5 text-black opacity-25">In comparison to last month</h1>
            <h1 className={`text-2xl font-semibold ${isProfit ? "text-[#52C41A]" : "text-red-500"} leading-8`}>{isProfit ? '↑' : '↓'} 40%</h1>
          </div>
        </div>
        <AreaChart data={orderData} width={237} height={202} yAxisLabel="Orders" />
      </div>
    </div>
  );
};

export default RecentOrders;

// {[0, 1, 2, 3, 4].map(i => {
//   const order = (i * maxorder) / (yAxisdatesCount - 1); // Calculate order for each line
//   const y = height - (i * height) / (yAxisdatesCount - 1);
//   return (
//     <g key={i}>
//       <line x1="0" y1={y} x2={width} y2={y} stroke="#e0e0e0" strokeWidth="1" />
//       <text x="-10" y={y + 5} fontSize="10" textAnchor="end" fill="#333">
//         {Math.round(order)}
//       </text>
//     </g>
//   );
// })}
