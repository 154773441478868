import { ImageWithBorder } from "../../../../components/common/ImageWithBorder";
import SectionHeader from "../../../../components/common/SectionHeader";
import { unloadingCargoData } from "../../../../utils/staticData";
import ExitIcon from "../../../../assets/icons/exit.svg";
import { statusBgColor } from "../../../../utils/statusBgColor";

const UnloadingCargo = () => {
  return (
    <div className="bg-white w-1/2 px-4 pb-3 rounded-lg">
      <SectionHeader title="Unloading Cargo" isViewAll showBorder />
      {unloadingCargoData.map((cargo, index) => (
        <div className="flex items-center justify-between mt-2 " key={index}>
          <h1 className="text-xs text-black opacity-65 uppercase leading-6">
            {cargo.name}
          </h1>
          <div className="flex flex-row items-center gap-3">
            <h1 className="text-xs leading-6 w-12 text-ellipsis overflow-hidden">
              {cargo.source}
            </h1>
            <div className="border-2 border-[#003EB3] w-10 mt-1"></div>
            <h1 className="text-xs leading-6 w-12 text-ellipsis overflow-hidden">
              {cargo.destination}
            </h1>
          </div>
          <div
            className={`text-xs  w-20 border text-center px-3 py-1 bg-opacity-30 rounded-md ${statusBgColor(
              cargo.status
            )}`}
          >
            {cargo.status}
          </div>
          <h1 className="text-xs text-black leading-6">{cargo.date}</h1>
          <ImageWithBorder src={ExitIcon} alt="Exit icon" isBorder />
          <ImageWithBorder src={ExitIcon} alt="Exit icon" isBorder />
        </div>
      ))}
    </div>
  );
};

export default UnloadingCargo;
